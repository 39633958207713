.z-40 {
    z-index: 1;
}

.text-grad-1 {
  background: linear-gradient(100deg, #AF47AB, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

.card-res-1 {
  border-left: none;
  padding-left: 0px;
  border-top: 1px solid #374151;
  padding-top: 20px;
}

.show-desktop {
  display: none;
}

.show-mobile {
  display: block;
}

@media (min-width: 768px) {
  .card-res-1 {
    padding-left: 20px;
    border-left: 1px solid #374151;
    border-top: none;
    padding-top: 0px;
  }
  .show-desktop {
    display: block;
  }
  .show-mobile {
    display: none;
  }
}